<template>
  <div class="shadow" style="z-index: 2; position: relative;">
    <div class="pt-2 container">
      <div class="row ms-3 me-3 g-4">
        <div class="col mb-3">
          <div class="dropdown-center">
            <button class="btn dropdown-toggle text-primary"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
              {{ tipoViagem.label }}
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" @click="onSelectTipoViagem(false)">Somente ida</a></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" @click="onSelectTipoViagem(true)">Ida e Volta</a></li>
            </ul>
          </div>
          <!--          <label for="exampleFormControlInput1" class="form-label text-primary">Origem</label>-->

          <Auto-complete v-model="origem.text"
                         :options="origem.cidadesList"
                         :show-spinner="procurandoCidadesOrigem"
                         placeholder="Digite a Origem"
                         @shouldSearch="procurarCidadesOrigem"
                         @select="onSelectOrigem"
                         label-key="nomeLocalidade"
                         value-key="id"
          />
        </div>
        <div class="col mb-3">
          <div class="dropdown-center">
            <button class="btn dropdown-toggle text-primary"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false">
              {{ passageiros }}
            </button>
            <ul class="dropdown-menu p-4" style="min-width: 260px;">
              <li class=" d-flex justify-content-between">
                  <div class="col-6">
                    Inteira
                  </div>
                  <div class="align-self-start">
                    <a @click="minusIntegral" :class="totalPassagens === 1 || quantidadePassageiros.integral === 0 ? 'text-secondary' : 'text-primary'"><feather type="minus-circle"></feather></a>
                    <label class="px-3">{{quantidadePassageiros.integral}}</label>
                    <a @click="somaIntegral" :class="totalPassagens === 5 ? 'text-secondary' : 'text-primary'"><feather type="plus-circle"></feather></a>
                  </div>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li class=" d-flex justify-content-between">
                <div class="col-6">
                  Estudante
                </div>
                <div class="align-self-start">
                  <a @click="minusEstudantes" :class="totalPassagens === 1 || quantidadePassageiros.estudante === 0 ? 'text-secondary' : 'text-primary'"><feather type="minus-circle"></feather></a>
                  <label class="px-3">{{quantidadePassageiros.estudante}}</label>
                  <a @click="somaEstudante" :class="totalPassagens === 5 ? 'text-secondary' : 'text-primary'"><feather type="plus-circle"></feather></a>
                </div>
              </li>
              <li v-show="quantidadePassageiros.estudante > 0">
                <div class="alert alert-danger" role="alert">
                  Para estudantes é necessario ter cadastro na Transpasse.
                </div>
              </li>
            </ul>
          </div>
          <!--          <label for="exampleFormControlInput1" class="form-label text-primary">Destino</label>-->
          <!--          <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com">-->
          <Auto-complete v-model="destino.text"
                         :options="destino.cidadesList"
                         :show-spinner="procurandoCidadesDestino"
                         placeholder="Digite o Destino"
                         @shouldSearch="procurarCidadesDestino"
                         @select="onSelectDestino"
                         label-key="nomeLocalidade"
                         value-key="id"
          />
        </div>
        <div class="col-sm-12 col-md-4 mb-3 d-flex">
          <!--          <label for="exampleFormControlInput1" class="form-label text-primary">Perido</label>-->
          <div class="align-self-end">
            <data-picker-component :is-ranger="tipoViagem.viagemRetorno" v-model="range"/>
          </div>
          <!--            <input type="text" aria-label="First name" class="form-control">-->

        </div>
        <div class="col-sm-12 col-md-4 mb-3 d-flex justify-content-center">
          <div class="align-self-end">
            <button type="submit" class=" btn btn-outline-danger" @click="onClickBuscar">Procurar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoComplete from "@/components/comuns/AutoComplete";
import DataPickerComponent from "@/components/comuns/DataPickerComponent";
import axios from "axios";
import moment from "moment";
// import Vue from "vue";

export default {
  name: 'FiltroViagens',
  components: {
    AutoComplete,
    DataPickerComponent
  },
  data() {
    return {
      procurandoCidadesOrigem: false,
      procurandoCidadesDestino:false,
      range: {
        start: moment(),
        end: '',
      },
      masks: {
        input: 'YYYY-MM-DD h:mm A',
      },
      origem: {
        text: '',
        cidadesList: []
      },
      destino: {
        text: '',
        cidadesList: []
      },
      data: new Date(),
      viagensList: {
        viagemIda: [],
        viagemVolta: []
      },
      tipoViagem: {
        viagemRetorno: false,
        label: "Somente ida"
      },
      quantidadePassageiros:{
        integral: 1,
        estudante: 0
      },
      cidadeOrigemId: 0,
      cidadeDestinoId: 0
    };
  },
  watch: {
    // tipoViagem(newValue) {
    //   this.$emit('tipoViagem', newValue);
    // }
  }, computed: {
    passageiros() {
      const totalPassageiros = this.quantidadePassageiros.estudante + this.quantidadePassageiros.integral;
      if (totalPassageiros <= 1){
        return totalPassageiros + " passageiro"
      }else {
        return totalPassageiros + " passageiros"
      }
    },
    totalPassagens(){
      return this.quantidadePassageiros.estudante + this.quantidadePassageiros.integral;
    }
  },
  methods: {
    minusIntegral(){
      if (this.quantidadePassageiros.integral > 0 && this.totalPassagens > 1){
        this.quantidadePassageiros.integral --;
      }
    },
    minusEstudantes(){
      if (this.quantidadePassageiros.estudante > 0 && this.totalPassagens > 1){
        this.quantidadePassageiros.estudante --;
      }
    },
    somaIntegral() {
      if (this.totalPassagens < 5){
        this.quantidadePassageiros.integral ++;
      }
    },
    somaEstudante() {
      if (this.totalPassagens < 5){
        this.quantidadePassageiros.estudante ++;
      }
    },
    atualizarDataViagem() {
      let dataViagem = {};
      const dataHoraPartida = moment(this.range.start).format("YYYY-MM-DD HH:mm");
      if (this.range.end) {
        const dataHoraRetorno = moment(this.range.end).format("YYYY-MM-DD HH:mm");
        dataViagem.viagemVolta = dataHoraRetorno;
      }

      dataViagem.viagemIda = dataHoraPartida;


      this.$emit('dataViagem', dataViagem);
    },
    procurarCidadesOrigem(query) {
      this.procurandoCidadesOrigem = true;
      // axios.get(process.env.VUE_APP_BASE_URL + `/internet/autocompleteCidade?q=${query}`).then(r => {
      axios.get(process.env.VUE_APP_BASE_URL + `/internet/autocompleteCidade?q=${query}`).then(r => {
        this.origem.cidadesList = r.data;
      }).finally(() =>  this.procurandoCidadesOrigem = false);
    },
    procurarCidadesDestino(query) {
      this.procurandoCidadesDestino = true;
      // axios.get(`http://localhost:8080/ipasses/internet/autocompleteCidade?q=${query}`).then(r => {
      axios.get(process.env.VUE_APP_BASE_URL + `/internet/autocompleteCidade?q=${query}`).then(r => {
        this.destino.cidadesList = r.data;
      }).finally(() =>  this.procurandoCidadesDestino = false);

    },
    onSelectOrigem(id) {
      this.cidadeOrigemId = id;
    },
    onSelectDestino(id) {
      this.cidadeDestinoId = id;
    },

    onSelectTipoViagem(vl) {
      this.tipoViagem.viagemRetorno = vl;

      if (!vl) {
        this.tipoViagem.label = "Somente ida";
      } else {
        this.tipoViagem.label = "Ida e Volta";
      }

      this.$emit('viagemRetorno', this.tipoViagem.viagemRetorno);
    },

    onClickBuscar() {

      let loader = this.$loading.show();

      const dataPartida = moment(this.range.start).format("YYYY-MM-DD");
      this.atualizarDataViagem();
      this.$emit('viagensList', []);

      let dataRetorno

      if (this.range.end) {
        dataRetorno = moment(this.range.end).format("YYYY-MM-DD");
      }

      const options = {
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL + '/internet/novoBuscarViagensAjax.json',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded"
        },
        data: {
          "dataPartida": dataPartida,
          "dataRetorno": dataRetorno,
          "idOrigem": this.cidadeOrigemId,
          "idDestino": this.cidadeDestinoId
        }
      };

      let _this = this;
      axios.request(options).then(r => {
        this.viagensList = r.data.viagensProximas;
        this.$emit('viagensList', this.viagensList);
        this.$emit('getQuantidadePassagens', this.quantidadePassageiros);
      }).catch(function (error) {
        if (error.response) {
          _this.$swal(
              {
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.mensagem,
              });
          // alert(error.response.data.mensagem);
        } else {
          _this.$swal(
              {
                icon: 'error',
                title: 'Oops...',
                text: "Não foi possivel conectar ao servidor... Tente novamente.",
              });
        }
      }).finally(() => loader.hide());

      // loader.hide();
    }
  },
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

/*ul {*/
/*  list-style-type: none;*/
/*  padding: 0;*/
/*}*/

/*li {*/
/*  display: inline-block;*/
/*  margin: 0 10px;*/
/*}*/

/*a {*/
/*  color: #42b983;*/
/*}*/
</style>
