<template>
  <div class="mt-3">
    <div class="onibus">
      <div class="bus-seat">
        <ul v-for="(fileiraInstance, fIndex) in fileirasPoltornas" :key="fIndex">
          <div v-for="(poltronaInstance, pIndex) in fileiraInstance" :key="pIndex">
            <li v-if="pIndex == 2" class="corredor"></li>
            <li class="poltrona"
                :class="poltronaInstance.statusPoltrona === 'M' ? 'poltronaMarcada' :
                  poltronaInstance.statusPoltrona === 'O' ? 'poltronaOcupada' : 'poltronaLivre' "
                @click="onSelect(fIndex, pIndex, poltronaInstance.label)">
              <div v-if="poltronaInstance.statusPoltrona === 'O'">&times;</div>
              <div v-else>
                {{ isLoading === poltronaInstance.label ? '' : poltronaInstance.label}}
                <div v-if="isLoading === poltronaInstance.label" class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "OnibusPoltronas",
  components: {},
  props: {
    loadingPoltrona: Number,
    maxPoltronasMarcadas: Number,
    marcacoes: Array,
    poltronas: {
      type: Array,
      default: () => []
    },
    ocupacoes:{
      type:Array,
      default: () => []
    },
    valorPassagem:{
      type: Number,
      default: 0
    },
    tipoPassagem:{
      type:String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: null,
      originalList: null,
      poltronasList:[],
      fileirasPoltornas: [],
      ocupacoesList: [],
      marcacaoList:[]
    }
  },
  watch: {
    marcacoes(nv){
      this.marcacaoList = nv;
    },
    poltronas() {
      this.cloneList();
      this.marcacaoList = [];
      this.montarCadeiras();
      this.marcarPoltronas();
    },
    ocupacoes(){
      this.cloneList();
      this.marcarOcupacoes();
    },
    loadingPoltrona(newValue){
      this.isLoading = newValue;
    }
  },
  created() {
    this.cloneList();
    this.montarCadeiras();
  },
  methods: {
    onSelect(fileira, poltrona, label){

      if (this.marcacaoList.length >= this.maxPoltronasMarcadas ){
        if (this.marcacaoList.find((obj) => obj.label === label)){
          this.marcarPoltrona(fileira, poltrona);
        } else{
          this.$swal(
              {
                icon: 'error',
                title: 'Oops...',
                text: "Marque apenas "+this.maxPoltronasMarcadas+" passagens por viagem",
              });
          // alert("Marque apenas "+this.maxPoltronasMarcadas+" passagens por viagem");
        }
        return;
      }

      this.$emit('marcarPoltrona', label);
      this.marcarPoltrona(fileira, poltrona);
    },

    marcarPoltrona(fileira, poltrona){
      this.fileirasPoltornas.forEach((o, index) => {

        if (index === fileira){
          if (o[poltrona].statusPoltrona === 'M'){
            o[poltrona].statusPoltrona = 'L';
            this.marcacaoList = this.marcacaoList.filter((obj) => obj.index !== o[poltrona].index);
          }else if (o[poltrona].statusPoltrona === 'L'){
            o[poltrona].statusPoltrona = 'M';
            o[poltrona].tipo = this.tipoPassagem;
            o[poltrona].preco = this.valorPassagem;
            this.marcacaoList.push(o[poltrona]);
          }
        }
      });

      this.$emit('select', this.marcacaoList);
    },

    montarCadeiras() {
      let numeroColunas = 0;
      this.poltronasList = [];
      this.fileirasPoltornas = [];

      for (let poltronaInstance in this.originalList) {
        let objeto = {
          label: this.originalList[poltronaInstance].label,
          index: this.originalList[poltronaInstance].index,
          statusPoltrona: 'L'
        }
        this.poltronasList.push(objeto);

        if (this.fileirasPoltornas[numeroColunas] == null){
          this.fileirasPoltornas.push([ this.poltronasList[poltronaInstance]]);
        }
        else if (this.fileirasPoltornas[numeroColunas].length < 4) {
          this.fileirasPoltornas[numeroColunas].push(this.poltronasList[poltronaInstance]);
        } else {
          numeroColunas++;
          this.fileirasPoltornas.push([this.poltronasList[poltronaInstance]]);
        }
      }

      this.marcarOcupacoes();
    },

    marcarOcupacoes(){
      for (let ocupacao in this.ocupacoesList){
        this.poltronasList.find((o) => o.index === this.ocupacoesList[ocupacao].index).statusPoltrona = 'O';
        this.marcacaoList = this.marcacaoList.filter((obj) => obj.index !== this.ocupacoesList[ocupacao].index);
      }
      this.$emit('select', this.marcacaoList);
    },

    marcarPoltronas(){
      for (let marcacaoInstance in this.marcacaoList){
        this.poltronasList.find((o) => o.index === this.marcacaoList[marcacaoInstance].index).statusPoltrona = 'M';
      }
    },

    cloneList(){
      this.originalList = JSON.parse(JSON.stringify(this.poltronas));
      this.ocupacoesList = JSON.parse(JSON.stringify(this.ocupacoes));
      // this.montarCadeiras();
    }
  },
}
</script>

<style scoped>
.onibus {
  height: 14rem;
  width: 45rem;
  filter: invert(120%) sepia(14%) saturate(523%) hue-rotate(170deg) brightness(96%) contrast(90%);
  margin: 2rem 3rem;
  padding: 11px 13px 19px 90px;
  background-image: url("./bus.svg");
  background-repeat: no-repeat;
  color: #ffffff;
}

.bus-seat {
  display: flex;
  height: 176px;
  margin: 0;
  z-index: 1;
}

.bus-seat ul {
  list-style: none;
  padding-left: 0px;
}

.bus-seat ul li {
  text-align: center;
  vertical-align: center;
  position: relative;
  height: 2rem;
  width: 2rem;
  margin: 0.5rem;
}

.bus-seat ul li div {
  color: #000000;
  font-weight: 700;
  padding: 15%;
}

.poltrona{
  border-radius: 8px;
}

.poltronaLivre {
  background-color: rgb(91 112 63);
}

.poltronaLivre:hover {
  border-radius: 8px;
  outline: 0.2rem solid #7DA944;
  cursor: pointer;
}

.poltronaMarcada {
  background-color: rgb(158 128 96);
}
.poltronaMarcada:hover {
  border-radius: 8px;
  outline: 0.2rem solid rgb(194 173 159);
  cursor: pointer;
}

.poltronaOcupada {
  background-color: rgb(102 102 102);
}

.poltronaOcupada:hover {
  cursor: not-allowed;
}

@media screen and (max-width: 1210px) {
  .onibus {
    width: 35rem;
    padding-top: 0px;
    padding-left: 55px;
  }

  .bus-seat ul li {
    position: relative;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.5rem;
  }
  .bus-seat ul li div {
    font-size: 13px;
  }
}

@media screen and (max-width: 992px) {
  .onibus {
    width: 30rem;
  }

  .bus-seat ul li {
    margin: 0.3rem;
  }
}


@media screen and (max-width: 763px) {
  .onibus {
    transform: rotate(90deg) translateY(239px);
    height: 30rem;
  }

  .bus-seat ul li {
    transform: rotate(-90deg);
  }
}

@media screen and (max-width: 567px) {
  .onibus {
    transform: rotate(90deg) translateY(322px);
  }
}
</style>