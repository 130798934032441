<template>
  <div>
    <p class="mt-5 fs-6 fst-italic text-primary">Resumo da <b>Viagem de Ida</b></p>
    <div class="passagens flex-grow-1 p-3 pt-2">
      <listagem-poltronas
          :lista-poltronas="listaPoltronas.viagemIda"
          label-key="label"
          type-key="tipo"
          price-key="preco"
          :permitir-remover-poltrona="!etapaPagamento"
          @removePoltrona="removePoltronaIda"
      />

      <p v-if="listaPoltronas.viagemVolta.length > 0" class="mt-5 fs-6 fst-italic text-primary">Resumo da <b>Viagem de
        Volta</b></p>
      <listagem-poltronas
          :lista-poltronas="listaPoltronas.viagemVolta"
          label-key="label"
          type-key="tipo"
          price-key="preco"
          :permitir-remover-poltrona="!etapaPagamento"
          @removePoltrona="removePoltronaVolta"
      />
    </div>
  </div>
</template>

<script>
import ListagemPoltronas from "@/components/comuns/ListagemPoltronas.vue";

export default {
  name: "detalhesPoltronasMarcadas",
  components: {ListagemPoltronas},
  props:{
    marcacoesList: {
      type: Object,
      default: () => ({
        viagemIda: [],
        viagemVolta: []
      })
    },
    etapaPagamento: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    marcacoesList(newValue) {
      this.listaPoltronas = newValue;
      // this.somarPassagens();
    }
  },
  methods: {
    removePoltronaIda(poltrona) {

      this.listaPoltronas.viagemIda.forEach((obj) => {
        if (obj.label === poltrona) {
          obj.statusPoltrona = 'L'
        }
      });
      this.listaPoltronas.viagemIda = this.listaPoltronas.viagemIda.filter((obj) => obj.label != poltrona);
    },
    removePoltronaVolta(poltrona) {
      this.listaPoltronas.viagemVolta.forEach((obj) => {
        if (obj.label === poltrona) {
          obj.statusPoltrona = 'L'
        }
      });
      this.listaPoltronas.viagemVolta = this.listaPoltronas.viagemVolta.filter((obj) => obj.label != poltrona);
    }
  },
  created() {
    this.listaPoltronas = this.marcacoesList;
  },
  data() {
    return {
      listaPoltronas: {},
      widowWidth: window.innerWidth,
    }
  },
}
</script>

<style scoped>
.passagens {
  overflow: auto;
}
</style>