<template>
  <div>
    <v-date-picker v-if="isRanger" :columns="$screens({ default: 1, lg: 2 })" v-model="range" :min-date="new Date()"
                   is-range>
      <template v-slot="{ inputValue, inputEvents, isDragging }">
        <div class="input-group">
          <span class="input-group-text">Ida</span>
          <input
              v-mask="'##/##/####'"
              class="form-control"
              placeholder="Data"
              :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
              :value="inputValue.start"
              v-on="inputEvents.start"
          />
          <span class="input-group-text">Volta</span>
          <input
              v-mask="'##/##/####'"
              class="form-control"
              placeholder="Data"
              :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
              :value="inputValue.end"
              v-on="inputEvents.end"
          />
        </div>
      </template>
    </v-date-picker>
    <v-date-picker v-if="!isRanger" v-model="range.start" :min-date="new Date()">
      <template v-slot="{ inputValue, inputEvents, isDragging }">
        <div class="input-group">
          <span class="input-group-text">Ida</span>
          <input
              v-mask="'##/##/####'"
              class="form-control"
              placeholder="Data"
              :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
              :value="inputValue"
              v-on="inputEvents"
          />
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
export default {
  name: "DataPickerComponent",
  props: {
    isRanger: Boolean,
    value: {
      type: Object,
      default: () => ({
        start: '',
        end: ''
      })
    },
  },
  watch: {
    value(newValue) {
      this.range = newValue;
    },
    range(vl) {
      this.$emit('input', vl);
    }
  },
  data() {
    return {
      range: {
        start: '',
        end: ''
      },
      date: new Date()
    }
  },
  created() {
    this.range = this.value;
  }
}
</script>

<style scoped>

</style>