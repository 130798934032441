<template>
  <div class="d-flex flex-md-column flex-sm-row">
    <div v-for="(poltronaInstance, index) in poltronasMarcadas" :key="index" class="mt-3">
      <div class="card me-3 shadow">
        <div class="card-body">
          <div class="d-flex flex-row align-items-stretch">
            <div class="poltrona bg-primary">
              <div>{{ poltronaInstance[labelKey] }}</div>
            </div>
            <div class="text-primary ms-2 flex-grow-1 me-auto" style="padding-top: 0.5rem">
              {{ poltronaInstance[typeKey] }}
            </div>
            <a v-show="permitirRemoverPoltrona" href="#" class="ms-auto pt-1" @click="removePoltrona(poltronaInstance[labelKey])"><feather class=" align-self-center text-danger" size="1.5rem" type="trash-2"></feather></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListagemPoltronas",
  props: {
    permitirRemoverPoltrona: {
      type: Boolean,
      default: true
    },
    listaPoltronas: {
      type: Array,
      default: () => []
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    typeKey: {
      type: String,
      default: 'tipo'
    },
    priceKey: {
      type: String,
      default: 'preco'
    },
  },
  data() {
    return {
      poltronasMarcadas: []
    }
  },
  watch: {
    listaPoltronas(vl) {
      this.poltronasMarcadas = vl;
    }
  },
  created() {
    this.poltronasMarcadas = this.listaPoltronas;
  },
  methods: {
    formatNumber(numero) {
      let real = Intl.NumberFormat('pt-BR', {
        style: "currency",
        currency: "BRL",
      });
      return real.format(numero);
    },
    removePoltrona(poltrona){
      this.$emit('removePoltrona', poltrona);
    }
  },
}
</script>

<style scoped>
.card {
  border: hidden;
}

.card .poltrona {
  /*background-color: orange;*/
  width: 2.5rem;
  height: 2.5rem;
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  border-radius: 8px;
  text-align: center;
}

.card .poltrona div {
  padding-top: 0.4rem;
}


</style>