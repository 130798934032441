<script src="https://sdk.mercadopago.com/js/v2"></script>
<template>
  <div v-if="widowWidth > 900">
    <NavBar :exibir-contador="true" :token="token"/>
    <!--    <filtro-viagens/>-->
    <main class="">
      <div class="row" style="height: calc(100vh - 7rem)">
        <div class="col-sm-12 col-lg-8 scroll">
          <div class="container">
            <div class="ms-md-5 mb-5">
              <dados-passageiro :validacao-dados="validacaoDados" :marcacoes="marcacoesList"
                                :quantidade-passageiros="quantidadePassagens"
                                :token="token"
                                @inputDadosComprador="getDadosComprador"
                                @consultarPassageiro="consultarEstudante"
                                @inputDadosPassageiros="getDadosPassageiros"></dados-passageiro>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 ps-0">
          <detalhes-compra :viagem-retorno="marcacoesList.viagemVolta && marcacoesList.viagemVolta.length > 0" :marcacoes-list="marcacoesList" :taxa="taxa"
                           :etapa-pagamento="true" :quantidade-passageiros="quantidadePassagens" :valor-total="valorTotal" :valor-sem-taxa="valorSemTaxa"
                           label-btn="PAGAR" @onClick="onClick"/>
        </div>
      </div>
    </main>
    <footer-componente/>
  </div>

  <div v-else>
    <NavBar :exibir-contador="true"/>
    <!--    <filtro-viagens/>-->
    <main class="">
      <div class="d-flex flex-column" style="min-height: calc(120vh - 7rem)">
        <div class="col-sm-12 col-lg-8 scroll">
          <div class="container">
            <div class="ms-md-5 mb-5">
              <div class="my-5">
                <p class="fs-4 text-primary">Confirmação e <b>dados dos passageiros</b></p>
              </div>
              <detalhes-poltronas-marcadas v-show="marcacoesList.viagemIda.length > 0 || marcacoesList.viagemVolta.length > 0" :marcacoes-list="marcacoesList" :etapa-pagamento="true"/>
              <dados-passageiro :validacao-dados="validacaoDados" :marcacoes="marcacoesList"
                                :quantidade-passageiros="quantidadePassagens"
                                @inputDadosComprador="getDadosComprador"
                                @consultarPassageiro="consultarEstudante"
                                @inputDadosPassageiros="getDadosPassageiros"></dados-passageiro>
            </div>
          </div>
        </div>
        <detalhes-compra
            :viagem-retorno="marcacoesList.viagemVolta.length > 0"
            :marcacoes-list="marcacoesList" :taxa="taxa" :etapa-pagamento="true"
            :quantidade-passageiros="quantidadePassagens"
            label-btn="PAGAR" @onClick="onClick"
        />
      </div>
    </main>
    <footer-componente/>
  </div>

</template>

<script>
import NavBar from "@/components/comuns/Navbar";
import DetalhesCompra from "@/components/DetalhesCompra";
import DadosPassageiro from "@/components/passageiro/DadosPassageiro";
import axios from "axios";
import FooterComponente from "@/components/comuns/FooterComponente.vue";
import DetalhesPoltronasMarcadas from "@/components/comuns/detalhesPoltronasMarcadas.vue";

const mp = new MercadoPago('APP_USR-81ea87a1-4a76-4dce-acc3-dd312942ac67', {
  locale: 'pt-BR'
});

// document.getElementById("go-back").addEventListener("click", function () {
//   $(".container_payment").fadeOut(500);
//   setTimeout(() => {
//     $(".shopping-cart").show(500).fadeIn();
//   }, 500);
//   $('#checkout-btn').attr("disabled", false);
// });

export default {
  name: 'InformacoesPassageiro',
  props: {
    // l: String,
    // t: Number,
    token: String,
    // linhaId: String,
    // quantidadePassagens: Object,
    // marcacoes: {
    //   type: Object,
    //   default: () => ({
    //     viagemIda: [],
    //     viagemVolta: []
    //   })
    // },
  },
  components: {
    DadosPassageiro,
    NavBar,
    DetalhesCompra,
    FooterComponente,
    DetalhesPoltronasMarcadas
  },
  data() {
    return {
      l: '',
      t: 0,
      linhaId: '',
      quantidadePassagens: {},
      widowWidth: window.innerWidth,
      marcacoesList: {},
      validacaoDados: false,
      comprador: {
        nome: '',
        email: '',
        telefone: ''
      },
      // marcacoes: {
      //   viagemIda: [],
      //   viagemVolta: []
      // },
      passageiros: [],
      preferenceId: '',
      valorTotal: 0.0,
      valorSemTaxa: 0.0,
    }
  },
  watch: {
    // marcacoes(newValue) {
    //   this.marcacoesList = newValue;
    // }
  },
  created() {
    // this.marcacoesList = this.marcacoes;
  },
  destroyed() {
    clearInterval(this.interval);
  },
  mounted() {
    window.onresize = () => {
      this.widowWidth = window.innerWidth;
    }

    setTimeout(this.consultarPagamento, 5000);

    console.log("buscar informações com token do lote");
    this.informacoesOcupacaoLote();
  },
  computed: {
    taxa() {
      //console.log("teste " + this.t);
      return this.t;
    },
    numerosDocs() {
      let numeros = [];

      this.passageiros.forEach((o) => numeros.push("\'" + o.numDoc + "\'"));

      return "[" + numeros.join(",") + "]";
    },
    tiposDocs() {
      let numeros = [];

      this.passageiros.forEach((o) => numeros.push("\'" + o.tipoDoc + "\'"));

      return "[" + numeros.join(",") + "]";
    },
    nomesPassageiros() {
      let numeros = [];

      this.passageiros.forEach((o) => numeros.push("\'" + o.nome + "\'"));

      return "[" + numeros.join(",") + "]";
    },
    tiposPassageiros() {
      let numeros = [];

      this.passageiros.forEach((o) => numeros.push("\'" + o.tipoPassagem + "\'"));

      return "[" + numeros.join(",") + "]";
    },
  },
  methods: {
    consultarEstudante(vl) {
      const options = {
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL + '/internet/consultarPassageiro',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded"
        },
        data: {
          nome: vl.nomeCompleto,
          cpf: vl.numDoc,
          linhaId: this.linhaId
        }
      };

      axios.request(options).then(function (response) {
        //console.log(response.data);
        vl.estudanteValido = response.data.passageiroCadastrado;
      }).catch(function (error) {
        console.error(error);
      });
    },

    checarDados() {

      if (!this.comprador.email ||
          !this.comprador.telefone ||
          !this.comprador.nome) {
        return false;
      }

      //console.log("Chegou aqui");

      let passageiro = this.passageiros.find((o) => {
        //console.log("o.estudanteValido === false -> " + o.estudanteValido === false);
        //console.log("!o.nomeCompleto -> " + !o.nome);
        //console.log("!o.numDoc -> " + !o.numDoc);
        // //console.log( o.estudanteValido === false || !o.nomeCompleto || !o.numDoc);
        return o.estudanteValido === false || !o.nome || !o.numDoc
      });

      //console.log(passageiro);

      //console.log(passageiro == null);

      return passageiro == null;
    },

    onClick() {
      this.validacaoDados = true;

      let loader = this.$loading.show();

      // let preferencias;

      const options = {
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL + '/internet/requisicaoPagamento.json',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded"
        },
        data: {
          "loteOcupacaoId": this.l,
          "emailComprador": this.comprador.email,
          "numeroComprador": this.comprador.telefone,
          "nomeCompletoComprador": this.comprador.nome,
          "numeroDoc": this.numerosDocs,
          "tipoDoc": this.tiposDocs,
          "nomeCompleto": this.nomesPassageiros,
          "tarifa": this.tiposPassageiros,
          "passagensEstudante": this.quantidadePassagens.estudante,
          "passagensIntegral": this.quantidadePassagens.integral
          // "listaPoltronasVolta":
        }
      };

      //console.log(options.data);
      let _this = this;
      axios.request(options).then(r => {
        //console.log(r);
        this.preferenceId = r.data.preferenceId;

        //console.log(this.preferenceId);
        this.criarRequisicaoPagamento(this.preferenceId);
      }).catch(function (error) {
        if (error.response) {
          _this.$swal(
              {
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.mensagem,
              });
          // alert(error.response.data.mensagem);
        } else {
          _this.$swal(
              {
                icon: 'error',
                title: 'Oops...',
                text: "Não foi possivel conectar ao servidor... Tente novamente.",
              });
        }
      }).finally(() => loader.hide());
    },

    criarRequisicaoPagamento(referencia) {
      mp.checkout({
        preference: {
          id: referencia
        },
        autoOpen: true,
      });
    },

    getDadosComprador(vl) {
      //console.log(vl);
      this.comprador = vl;

      const options = {
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL + '/internet/atualizarDadosComprador.json',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded"
        },
        data: {
          "token": this.token,
          "email": this.comprador.email,
          "telefone": this.comprador.telefone,
          "nomeComprador": this.comprador.nome
          // "listaPoltronasVolta":
        }
      };

      var vm = this
      axios.request(options)
          .then(r => {
            console.log(r.data);
          })
          .catch(function (error) {

          });
    },
    getDadosPassageiros(vl) {
      //console.log(vl);
      this.passageiros = vl;
    },

    consultarPagamento() {
      const options = {
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL + '/internet/situacaoPagamento.json',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded"
        },
        data: {
          "loteOcupacaoId": this.l,
          "tokenLote": this.token,
        }
      };

      axios.request(options).then(r => {
        if(r.data.pagamentoConfirmado) {
          this.$router.push({
            path: '/success', query: {
              preference_id: this.preferenceId,
            }
          });
        }
      }).catch(reason => {
        if ([403, 404].indexOf(reason.response.status) !== -1) {
          if (this.$router.currentRoute.path !== '/') {
            this.$swal({
              icon: 'error',
              title: 'Tempo esgotado',
              text: "O tempo para preencher os dados de pagamento foi esgotado, por favor, inicie o processo novamente.",
            });
            this.$router.push('/');
            clearTimeout(timeout_verificapagamento);
          }
        }
      });

      const timeout_verificapagamento = setTimeout(this.consultarPagamento, 5000);
    },

    informacoesOcupacaoLote() {
      const options = {
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL + '/internet/informacoesOcupacaoLote.json',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded"
        },
        data: {
          "token": this.token,
        }
      };

      axios.request(options).then(r => {
        // l: String,
        //     t: Number,
        //     token: String,
        //     linhaId: String,
        //     quantidadePassagens: Object,
        //     marcacoes: {
        //   type: Object,
        // default: () => ({
        //     viagemIda: [],
        //     viagemVolta: []
        //   })
        // },
        this.l = r.data.lote;
        this.t = r.data.taxa;
        this.linhaId = r.data.linha;
        console.log(r.data.marcacoes);
        this.marcacoesList = r.data.marcacoes;
        this.quantidadePassagens = {
          'integral': r.data.qtdIntegral,
          'estudante': r.data.qtdEstudante,
        };
        this.valorTotal = r.data.valorTotal;
        this.valorSemTaxa = r.data.valorSemTaxa;
      }).catch(reason => {
      //   if ([403, 404].indexOf(reason.response.status) !== -1) {
      //     if (this.$router.currentRoute.path !== '/') {
      //       this.$swal({
      //         icon: 'error',
      //         title: 'Tempo esgotado',
      //         text: "O tempo para preencher os dados de pagamento foi esgotado, por favor, inicie o processo novamente.",
      //       });
      //       this.$router.push('/');
      //       clearTimeout(timeout_verificapagamento);
      //     }
      //   }
      });
    }
  },
}
</script>

<style>

.scroll {
  /*background-color: #545eb0;*/
  overflow-y: auto;
  height: 100%;
}

#app {
  overflow: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*color: #7DA944;*/
  --bs-primary-rgb: 125, 169, 68;
  --bs-body-bg: #f5f5f5;
  --bs-primary: #7DA944;
}
</style>
