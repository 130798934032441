<template>
  <div id="DadosPassageiro" class="my-5 pb-5 mb-5">
    <form>
      <div class="mt-5">
        <div class="card shadow">
          <div class="card-body">
            <p class="text-primary fs-5">Comprador</p>
            <div class="row">
              <div class="col-sm-12">
                <label for="exampleInputEmail1" class="form-label text-black-50">Nome completo*</label>
                <input v-model="comprador.nome" type="text" class="form-control" @blur="enviarDadosComprador"
                       :class="comprador.nome === '' && validacaoDados ? 'is-invalid' : ''" id="nomeComprador">
                <div class="invalid-feedback">
                  Preencha o nome completo do comprador.
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <label for="exampleInputEmail1" class="form-label text-black-50">E-mail *</label>
                <input v-model="comprador.email" type="email" class="form-control" @blur="enviarDadosComprador"
                       :class="comprador.email === '' && validacaoDados ? 'is-invalid' : ''" id="emailComprador">
                <div class="invalid-feedback">
                  Preencha o e-mail comprador.
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <label for="exampleInputEmail1" class="form-label text-black-50">&numero; de telefone *</label>
                <input
                    v-model="comprador.telefone" @blur="enviarDadosComprador"
                    v-mask="'(##) #####-####'"
                    type="text" class="form-control"
                    :class="comprador.telefone === '' && validacaoDados ? 'is-invalid' : ''" id="telefone">
                <div class="invalid-feedback">
                  Preencha o telefone de contato do comprador.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-for="(marcacao, index) in passageiros" :key="index"
             class="mt-5 card shadow">
          <div class="card-body">
            <div class="d-flex flex-column flex-md-row justify-content-between">
              <p class="text-primary fs-5">Passageiro #{{ index + 1 }} <span v-show="marcacao.tipoPassagem  === 'E'" class="badge bg-warning">{{tipoPassagem(marcacao.tipoPassagem)}}</span></p>
              <button class="btn btn-primary text-white" type="button" @click="copiarDadosComprador">Usar dados do comprador</button>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <label for="exampleInputEmail1" class="form-label text-black-50">Nome completo *</label>
                <input v-model="marcacao.nome" type="text" class="form-control" @blur="enviarDadosPassageiros"
                       :class="marcacao.nome === '' && validacaoDados ? 'is-invalid' : ''"
                       id="nomePassageiro">
                <div class="invalid-feedback">
                  Preencha o nome completo do passageiro.
                </div>
              </div>

              <div class="col-sm-12 col-md-2">
                <label for="exampleInputEmail1" class="form-label text-black-50">Documento*</label>
                <select v-model="passageiros[index].tipoDoc" @change="passageiros[index].numDoc = ''"
                        @blur="enviarDadosPassageiros"
                        class="form-select"
                        :class="passageiros[index].tipoDoc === '' && validacaoDados ? 'is-invalid' : ''"
                        aria-label="Default select example">
                  <option value="CPF">CPF</option>
                  <option v-if="marcacao.tipoPassagem !== 'E'" value="RG">RG</option>
                  <option v-if="marcacao.tipoPassagem !== 'E'" value="PASSAPORTE">Passaporte</option>
                </select>
                <div class="invalid-feedback">
                  Preencha o tipo do documento do passageiro.
                </div>
              </div>

              <div class="col-sm-12 col-md-4">
                <label for="exampleInputEmail1" class="form-label text-black-50">&numero; do documento*</label>
                <input v-model="passageiros[index].numDoc" type="text" class="form-control"
                       @blur="consultarDadosPassageiro(marcacao)"
                       v-mask="passageiros[index].tipoDoc === 'CPF' ? '###.###.###-##': ''"
                       :class="passageiros[index].numDoc === '' && validacaoDados ? 'is-invalid' : ''"
                       id="documentoPassageiro">
                <div class="invalid-feedback">
                  Preencha o numero do documento do passageiro.
                </div>
              </div>
            </div>
            <div v-show="marcacao.tipoPassagem === 'E' && !marcacao.estudanteValido" class=" mt-3 alert alert-danger" role="alert">
              <feather class="me-2" type="alert-circle"></feather> Estudante não cadastrado, para realizar o cadastro entre em contato com a Transpasse de posse da carteira de estudante e documento oficial com foto.
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: "DadosPassageiro",
  props: {
    token: String,
    quantidadePassageiros: {
      type: Object,
      default: () => ({
        integral: 0,
        estudante: 0
      })
    },
    validacaoDados: {
      type: Boolean,
      default: false
    },
    marcacoes: {
      type: Object,
      default: () => ({
        viagemIda: [],
        viagemVolta: []
      })
    },
  },
  watch: {
    marcacoes(newValue) {
      console.log('watch marcacoes');
      this.listaMarcacoes = newValue;
    },
    quantidadePassageiros() {
      this.listaFormPassageiros();
    }
  },
  methods: {
    copiarDadosComprador(){
      this.passageiros[0].nome = this.comprador.nome;
    },
    tipoPassagem(vl){
      if(vl=== "E"){
        return "Estudante"
      } else {
        return "Integral"
      }
    },
    enviarDadosComprador() {
      this.$emit('inputDadosComprador', this.comprador);
    },
    consultarDadosPassageiro(vl){
      if (vl.tipoPassagem === 'E'){
        this.enviarDadosPassageiros();
        this.consultarEstudante(vl);
      } else {
        this.enviarDadosPassageiros();
      }
    },
    enviarDadosPassageiros() {
      this.$emit('inputDadosPassageiros', this.passageiros);
    },
    consultarEstudante(vl){
      this.$emit('consultarPassageiro', vl);
    },
    listaFormPassageiros() {
      for (let i = 0 ; i < this.quantidadePassageiros.estudante; i ++){
        const informacoes = {tipoPassagem: "E", nome: '', tipoDoc: 'CPF', numDoc: '', estudanteValido: true};
        this.passageiros.push(informacoes);
      }

      for (let i = 0 ; i < this.quantidadePassageiros.integral; i ++){
        const informacoes = {tipoPassagem: "I", nome: '', tipoDoc: 'CPF', numDoc: ''};
        this.passageiros.push(informacoes);
      }
    }
  },
  data() {
    return {
      listaMarcacoes: [],
      comprador: {
        nome: '',
        email: '',
        telefone: ''
      },
      passageiros: [],
    }
  },
  created() {
    this.listaMarcacoes = this.marcacoes;

    // for (let i = 0 ; i < this.quantidadePassageiros.estudante; i ++){
    //     const informacoes = {tipoPassagem: "E", nome: '', tipoDoc: 'CPF', numDoc: '', estudanteValido: true};
    //     this.passageiros.push(informacoes);
    // }
    //
    // for (let i = 0 ; i < this.quantidadePassageiros.integral; i ++){
    //   const informacoes = {tipoPassagem: "I", nome: '', tipoDoc: 'CPF', numDoc: ''};
    //   this.passageiros.push(informacoes);
    // }

    // let token = this.$route.params.token;

    // console.log(token);
    axios.get(process.env.VUE_APP_BASE_URL + `/internet/dadosPassageiro?tokenLote=${this.token}`).then(r => {
      console.log(r.data);

      /*this.listaMarcacoes = r.data.marcacoes;

      for (let i = 0 ; i < r.data.quantidadePassageiros.estudante; i ++){
        const informacoes = {tipoPassagem: "E", nome: '', tipoDoc: 'CPF', numDoc: '', estudanteValido: true};
        this.passageiros.push(informacoes);
      }

      for (let i = 0 ; i < r.data.quantidadePassageiros.integral; i ++){
        const informacoes = {tipoPassagem: "I", nome: '', tipoDoc: 'CPF', numDoc: ''};
        this.passageiros.push(informacoes);
      }*/
    });

  },
  mounted() {
    this.listaFormPassageiros();
  },
}
</script>

<style scoped>
.card {
  border: hidden;
}

.card .poltrona {
  /*background-color: orange;*/
  width: 2.5rem;
  height: 2.5rem;
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  border-radius: 8px;
  text-align: center;
}

.card .poltrona div {
  padding-top: 0.4rem;
}
</style>