<template>
  <div>
    <nav-bar/>
    <main class="sucesso">
      <div class="mt-5 mb-5 d-flex justify-content-center">
        <div class="card shadow">
          <div class="card-body">
            <div class="d-flex justify-content-center flex-column">
              <feather class="m-3 align-self-center text-primary" size="4rem" type="check-circle"></feather>

              <label class="align-self-center text-warning fs-3">Pagamento <b>pendente.</b></label>
              <p class="fs-5 text mt-2 ms-5 me-5">
                O seu pagamento está pendente de confirmação, assim que confirmarmos o pagamento te enviaremos um e-mail com o seu voucher.
              </p>
              <div class="align-self-center mt-3 mb-3">
                <a class="btn btn-outline-primary fs-5 fw-semibold" href="/">Comprar outra passagem</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import NavBar from "@/components/comuns/Navbar";

export default {
  name: "PagamentoPendente",
  components: {
    NavBar,
  },
  data() {
    return {
      url: ""
    }
  },
  props: {
    p: String,
  },
  methods: {
    downloadBilhetes() {

    }
  },
  mounted() {
    this.url = process.env.VUE_APP_BASE_URL;
  },
  created() {
    // document.getElementById("").remove();
    document.getElementsByClassName("mp-mercadopago-checkout-wrapper")[0].remove();
  }
}
</script>

<style scoped>
.sucesso .card {
  border: hidden;
}

.sucesso .card .card-body {
  width: calc(100vw - 3rem);
}

@media screen and (min-width: 944px) {
  .sucesso .card .card-body {
    width: calc(100vw - 25rem);
  }
}
</style>