<template>
  <div id="ListagemViagem" class="mt-5">
    <p class="fs-4 text-primary">Escolha uma <b>{{labelViagem}}</b></p>
    <div v-for="(viagem, i) in listaViagens" :key="i" class="mt-5">
      <div class="card shadow" @click="buscarMarcacao(viagem)">
        <div class="card-body">
          <div class="row">
            <!--            Horairio da viagem-->
            <div class="col-sm-12 col-lg-8">
              <div class="row">
                <div class="col-3 text-end">
                  <div class="fs-5 fw-normal text-primary">
                    {{viagem.horaSaida}}
                  </div>
                  <div class="fs-5 fw-normal text-primary">
                    {{viagem.previsaoChegada}}
                  </div>
                </div>
                <div class="col-1">
                  <div class=" arrow dotted" style="margin-left: -25px;"></div>
                </div>
                <div class="col-8">
                  <div class="fs-5 fw-lighter">
                    {{viagem.pontoEmbarque}}
                  </div>
                  <div class="fs-5 fw-lighter">
                    {{ viagem.destino }}
                  </div>
                </div>
              </div>
            </div>

            <!--            informacoes viagem-->
            <div class="col-sm-12 col-lg-2 text-center">
              <span class="fw-normal">Convencional</span>
              <p class="fw-semibold text-primary">{{viagem.vagas}} Disponiveis</p>
            </div>

            <!--            valor da viagem -->
            <div class="col-sm-12 col-lg-2 text-center">
              <span class="fw-normal">Integral</span>
              <p class="fs-4 fw-semibold text-primary">{{formatNumber(viagem.linhaPreco.valor)}}</p>
            </div>
          </div>
          <div v-show="selecionado.idViagem === viagem.idViagem" class="container">
            <onibus-poltronas
                :poltronas="poltronas"
                :ocupacoes="ocupacoes"
                :marcacoes="marcacoes"
                :loading-poltrona="poltronaLoading"
                :valor-passagem="viagem.linhaPreco.valor"
                tipo-passagem="Convencional"
                :max-poltronas-marcadas="quantidadeMaximaPoltronasMarcadas"
                @marcarPoltrona="marcarPoltrona"
                @select="selecao"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import OnibusPoltronas from "@/components/Viagens/OnibusPoltronas";
import axios from "axios";

export default {
  name: "ListagemViagem",
  components: {
    OnibusPoltronas,
  },
  props: {
    dataViagem: String,
    labelViagem: {
      type: String,
      default: 'Viagem de ida'
    },
    viagens: {
      type: Array,
      default: () => []
    },
    listaMarcacoes: Array,
    quantidadeMaximaPoltronasMarcadas: {
      type: Number,
      default: 5
    }
  },
  watch: {
    listaMarcacoes(newValue){
      this.marcacoes = newValue;
    },
    viagens(newValue) {
      this.listaViagens = newValue;
    },
    dataViagem(newValue) {
      this.selecionado = {};
      if (newValue === undefined) {
        return null;
      }
      let dataViagemInstance = newValue.split(" ");
      this.viagem.diaViagem = dataViagemInstance[0];
      this.viagem.horaViagem = dataViagemInstance[1];
    }
  },
  data() {
    return {
      selecionado: {},
      valorPassagem: 0,
      listaViagens: [],
      viagem:{
        diaViagem: null,
        horaViagem: null
      },
      poltronas: [],
      ocupacoes: [],
      marcacoes: [],
      poltronaLoading: 0
    }
  },
  methods: {
    selecao(vl) {
      this.marcacoes = vl;
      this.$emit('listaMarcacoes', this.marcacoes);
    },
    buscarMarcacao(viagem) {
      if (this.selecionado.idViagem === viagem.idViagem) {return;}
      // //console.log(Object.keys(this.selecionado).length === 0);
      if (Object.keys(this.selecionado).length > 0) {
        if (!confirm("Deseja  realmente trocar a viagem?")){ return;}
      }
      this.poltronas = [];
      this.ocupacoes = [];
      this.selecionado = viagem;

      const options = {
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL + '/internet/selecionarViagem.json',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded"
        },
        data: {
          "idViagem": viagem.idViagem,
          "data": this.viagem.diaViagem,
          "horaSaida": viagem.horaViagem,
          "idLinha": viagem.linhaPreco.idLinha,
          "idPercurso": viagem.linhaPreco.idPercurso,
          "dataViagemRetorno": null,
          "idLote": null
        }
      };

      let _this = this;
      axios.request(options).then(r => {
        this.poltronas = r.data.poltronas;
        this.ocupacoes = r.data.ocupacoes;
      }).catch(function (error) {
        if (error.response) {
          _this.$swal(
              {
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.mensagem,
              });
          // alert(error.response.data.mensagem);
        }else{
          _this.$swal(
              {
                icon: 'error',
                title: 'Oops...',
                text: "Não foi possivel conectar ao servidor... Tente novamente.",
              });
        }
      });

      this.$emit('viagemSelecionada', this.selecionado);
    },
    formatNumber(numero) {
      let real = Intl.NumberFormat('pt-BR', {
        style: "currency",
        currency: "BRL",
      });
      return real.format(numero);
    },

    marcarPoltrona(poltrona) {

      this.poltronaLoading = poltrona;
      const viagem = this.selecionado;

      const options = {
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL + '/internet/selecionarPoltrona',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          idViagem: viagem.idViagem,
          idLinha: viagem.linhaPreco.idLinha,
          idPercurso: viagem.linhaPreco.idPercurso,
          poltrona: poltrona,
          data:  this.viagem.diaViagem,
          horaSaida: viagem.horaSaida
        }
      };

      let _this = this;
      axios.request(options).then(r => {
        this.alterarTaxa(r.data.taxa);
        this.ocupacoes = r.data.ocupacoes;
      }).catch(function (error) {
        if (error.response) {
          _this.$swal(
              {
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.mensagem,
              });
          // alert(error.response.data.mensagem);
        }else{
          _this.$swal(
              {
                icon: 'error',
                title: 'Oops...',
                text: "Não foi possivel conectar ao servidor... Tente novamente.",
              });
        }
      }).finally(() => this.poltronaLoading = 0);
    },
    alterarTaxa(vl){
      // //console.log(vl);
      this.$emit('taxaOnline', vl);
    }
  },
}
</script>

<style scoped>

.card {
  border: hidden;
  border-radius: 1rem;
}

.arrow {
  transform: rotate(90deg);
  width: 50px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: relative;
  top: 20px;
}

.arrow:after {
  content: "";
  display: inline-block;
  border-radius: 4px;
  width: 20px;
  height: 4px;
  background-color: #000;
  transform: rotate(45deg);
  position: absolute;
  right: -5px;
  bottom: 7px;
}

.arrow:before {
  content: "";
  display: inline-block;
  border-radius: 4px;
  width: 20px;
  height: 4px;
  background-color: #000;
  transform: rotate(-45deg);
  position: absolute;
  right: -5px;
  bottom: -6px;
}

#ListagemViagem .card > .card-body > .row {
  cursor: pointer;
}
</style>