<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: 'App',
}
</script>

<style>

#app {
  overflow: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*color: #7DA944;*/
  --bs-primary-rgb: 125, 169, 68;
  --bs-body-bg: #f5f5f5;
  --bs-primary: #7DA944;
}
</style>
