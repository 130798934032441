<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-primary">
    <div class="container-fluid">
      <span class="navbar-brand mb-0 h1" @click="telaInicial" style="cursor: pointer"><strong>TRANSPASSE</strong></span>
      <span v-if="exibirContador" class="navbar-brand mb-0 h1"><strong>{{min}}:{{seg}}</strong></span>
    </div>
  </nav>
</template>

<script>
import axios from "axios";

export default {
  name: 'NavBar',
  props: {
    msg: String,
    token: String,
    exibirContador: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      min: "00",
      seg: "00",
      time: 0
    }
  },
  mounted() {
    if(this.exibirContador){
     this.tempoSessao();
      this.countdown();
    }
  },
  methods: {
    telaInicial(){
      if (this.$route.name !== 'home') {
        this.$router.push({name: 'home'});
      }else {
        this.$router.go();
      }

    },
    countdown() {
      // Se o tempo não for zerado
      if ((this.time - 1) >= -1) {

        // Pega a parte inteira dos minutos
        var minutes = parseInt(this.time / 60);
        // Calcula os segundos restantes
        var segunds = this.time % 60;

        // Formata o número menor que dez, ex: 08, 07, ...
        if (minutes < 10) {
          minutes = "0" + minutes;
          minutes = minutes.substr(0, 2);
        }
        if (segunds <= 9) {
          segunds = "0" + segunds;
        }

        this.min = minutes;
        this.seg = segunds;

        // Define que a função será executada novamente em 1000ms = 1 segundo
        setTimeout(this.countdown, 1000);

        // diminui o tempo
        this.time --;
      }
    },

    tempoSessao() {
      const options = {
        method: 'POST',
        url: process.env.VUE_APP_BASE_URL + '/internet/tempoSessao.json',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded"
        },
        data: {
          "token": this.token,
        }
      };

      axios.request(options).then(r => {
        this.time = r.data.tempoSessao;
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*h3 {*/
/*  margin: 40px 0 0;*/
/*}*/
/*ul {*/
/*  list-style-type: none;*/
/*  padding: 0;*/
/*}*/
/*li {*/
/*  display: inline-block;*/
/*  margin: 0 10px;*/
/*}*/
/*a {*/
/*  color: #42b983;*/
/*}*/
</style>
