<template>
  <!--  Inicio desktop -->
  <div v-if="widowWidth > 900">
    <NavBar/>
    <filtro-viagens @viagemRetorno="getTipoViagem"
                    @dataViagem="getDataViagem"
                    @viagensList="getViagens"
                    @getQuantidadePassagens="getQuantidadePqssageiros"/>
    <main class="">
      <div class="row principal">
        <div class="col-sm-12 col-lg-8 scroll">
          <filtro-viagens v-show="widowWidth <= 900" @viagemRetorno="getTipoViagem" @dataViagem="getDataViagem" @viagensList="getViagens"
                          @getQuantidadePassagens="getQuantidadePqssageiros"/>
          <div class="container">
            <div class="ms-md-5 mb-5">
              <div v-if="!viagensIdasEncontradas || (viagemRetorno && !viagensVoltaEncontradas)">
                <div class="mt-5">
                  <p class="fs-4 text-primary">Nenhuma <b>viagem encontrada</b></p>
                  <div v-if="!cidades.isEmpty">
                    <p class="fs-6 text-primary text-secondary">Cidades atendidas</p>
                  </div>
                  <div v-if="!cidades.isEmpty" class="container text-center">
                    <div class="row">
                      <div v-for="(opt, index) in cidades" class="col-3" :key="index">
                        <div class="text-sm-start text-secondary" style="font-size: 0.8rem;"> <font-awesome-icon icon="fa-regular fa-circle" size="2xs"/>  {{opt["nomeLocalidade"]}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row pt-2">
                    <div class="col-2">
                      <img src="../assets/imgs/logo-alves.png" width="80">
                    </div>
                    <div class="col-2">
                      <img src="../assets/imgs/logo-cabral.jpg" width="80" style="mix-blend-mode: multiply;">
                    </div>
                    <div class="col-2">
                      <img src="../assets/imgs/logo-jardinense.png" width="80">
                    </div>
                    <div class="col-2">
                      <img src="../assets/imgs/logo-riograndense.png" width="80">
                    </div>
                    <div class="col-2">
                      <img src="../assets/imgs/nordeste-logo.png" width="80">
                    </div>
                    <div class="col-2">
                      <img src="../assets/imgs/oceano-logo.png" width="80">
                    </div>
                  </div>
                </div>
              </div>
              <listagem-viagem v-show="!viagemRetorno && viagensIdasEncontradas"
                               :data-viagem="dataViagem.viagemIda"
                               :viagens="viagensList.viagensIda"
                               @taxaOnline="getTaxa"
                               :lista-marcacoes="marcacoes.viagemIda"
                               :quantidade-maxima-poltronas-marcadas="totalPassagens"
                               @viagemSelecionada="getViagemSelecionadoIda"
                               @listaMarcacoes="getMarcacoesIda"/>

              <listagem-viagem v-show="viagemRetorno && viagensVoltaEncontradas"
                               label-viagem="Viagem de Volta"
                               :data-viagem="dataViagem.viagemVolta"
                               :viagens="viagensList.viagensVolta"
                               :lista-marcacoes="marcacoes.viagemVolta"
                               :quantidade-maxima-poltronas-marcadas="totalPassagens"
                               @viagemSelecionada="getViagemSelecionadoVolta"
                               @listaMarcacoes="getMarcacoesVolta"/>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 ps-0 scroll" v-show="widowWidth > 900" >
          <detalhes-compra v-show="widowWidth > 900"  :marcacoes-list="marcacoes" :viagem-retorno="viagemRetorno" :quantidade-passageiros="quantidadePassagens" :taxa="taxa" @onClick="onClick"/>
        </div>
      </div>
    </main>
    <footer-componente/>
  </div>
  <!--  Fim desktop-->
  <!--  Inico mobile-->
  <div v-else>
    <NavBar/>
    <main class="">
      <div class="d-flex flex-column principal">
        <div class="col-sm-12 col-lg-8 scroll">
          <filtro-viagens
              @viagemRetorno="getTipoViagem"
              @dataViagem="getDataViagem"
              @viagensList="getViagens"
              @getQuantidadePassagens="getQuantidadePqssageiros"/>
          <div class="container">
            <div class="ms-md-5 mb-5">
              <div v-if="!viagensIdasEncontradas || (viagemRetorno && !viagensVoltaEncontradas)">
                <div class="mt-5">
                  <p class="fs-4 text-primary">Nenhuma <b>viagem encontrada</b></p>
                </div>
              </div>
              <detalhes-poltronas-marcadas v-show="marcacoes.viagemIda.length > 0 || marcacoes.viagemVolta.length > 0" :marcacoes-list="marcacoes" :etapa-pagamento="false"/>
              <listagem-viagem v-show="!viagemRetorno && viagensIdasEncontradas"
                               :data-viagem="dataViagem.viagemIda"
                               :viagens="viagensList.viagensIda"
                               @taxaOnline="getTaxa"
                               :lista-marcacoes="marcacoes.viagemIda"
                               :quantidade-maxima-poltronas-marcadas="totalPassagens"
                               @viagemSelecionada="getViagemSelecionadoIda"
                               @listaMarcacoes="getMarcacoesIda"/>

              <listagem-viagem v-show="viagemRetorno && viagensVoltaEncontradas"
                               label-viagem="Viagem de Volta"
                               :data-viagem="dataViagem.viagemVolta"
                               :viagens="viagensList.viagensVolta"
                               :lista-marcacoes="marcacoes.viagemVolta"
                               :quantidade-maxima-poltronas-marcadas="totalPassagens"
                               @viagemSelecionada="getViagemSelecionadoVolta"
                               @listaMarcacoes="getMarcacoesVolta"/>
            </div>
          </div>
        </div>
        <detalhes-compra :marcacoes-list="marcacoes"
                         :viagem-retorno="viagemRetorno"
                         :quantidade-passageiros="quantidadePassagens"
                         :taxa="taxa"
                         @onClick="onClick"/>
      </div>
    </main>
    <footer-componente/>
  </div>
  <!--  Fim mobile-->
  <!--  </div>-->
</template>

<script>
import NavBar from "@/components/comuns/Navbar";
import FiltroViagens from "@/components/FiltroViagens";
import DetalhesCompra from "@/components/DetalhesCompra";
import ListagemViagem from "@/components/Viagens/ListagemViagem";
import axios from "axios";
import FooterComponente from "@/components/comuns/FooterComponente.vue";
import DetalhesPoltronasMarcadas from "@/components/comuns/detalhesPoltronasMarcadas.vue";

export default {
  name: 'ListarViagens',
  components: {
    DetalhesPoltronasMarcadas,
    FooterComponente,
    NavBar,
    FiltroViagens,
    DetalhesCompra,
    ListagemViagem
  },
  data() {
    return {
      taxa: 0,
      widowWidth: window.innerWidth,
      viagemSelecionada: {
        ida: null,
        volta: null
      },
      quantidadePassagens: {
        integral: 0,
        estudante: 0
      },
      marcacoes: {
        viagemIda: [],
        viagemVolta: []
      },
      viagensList: {
        viagensIda: [],
        viagensVolta: []
      },
      idaVolta: false,
      dataViagem: {
        viagemIda: null,
        viagemVolta: null
      },
      viagemRetorno: false,
      cidades: []
    }
  },
  mounted() {
    window.onresize = () => {
      this.widowWidth = window.innerWidth;
    }
  },
  created() {
    const options = {
          method: 'GET',
          url: process.env.VUE_APP_BASE_URL + '/internet/listAllCidades.json',
          headers: {
            'Content-Type': "application/x-www-form-urlencoded"
          },
    }

    axios.request(options).then(r => {
      // //console.log(r.data);
      this.cidades = r.data;
    });
  },
  computed: {
    viagensIdasEncontradas() {
      // //console.log(this.viagensList.viagensIda);
      if (this.viagensList.viagensIda) {
        return this.viagensList.viagensIda.length > 0;
      } else {
        return false;
      }
    },
    viagensVoltaEncontradas() {
      // //console.log(this.viagensList.viagensVolta);
      if (this.viagemRetorno && this.viagensList.viagensVolta) {
        return this.viagensList.viagensVolta.length > 0;
      } else {
        return false;
      }
    },
    listaPoltronasIda() {
      let retorno = [];
      this.marcacoes.viagemIda.forEach((o) => {
        retorno.push(o.label);
      })
      return "[" + retorno.join(",") + "]";
    },

    listaPoltronasVolta() {
      if (this.marcacoes.viagemVolta) {
        let retorno = [];
        this.marcacoes.viagemVolta.forEach((o) => {
          retorno.push(o.label);
        })
        return "[" + retorno.join(",") + "]";
      } else {
        return null;
      }
    },

    totalPassagens() {
      return this.quantidadePassagens.estudante + this.quantidadePassagens.integral;
    },

    dataViagemIda() {
      const dataViagemIda = this.dataViagem.viagemIda.split(" ");
      return dataViagemIda[0];
    },
    dataViagemVolta() {
      if (this.dataViagem.viagemVolta) {
        const dataViagem = this.dataViagem.viagemVolta.split(" ");
        return dataViagem[0];
      } else {
        return null;
      }
    }
  },
  methods: {
    getMarcacoesIda(vl) {
      this.marcacoes.viagemIda = vl;
    },
    getMarcacoesVolta(vl) {
      this.marcacoes.viagemVolta = vl;
    },
    getViagens(vl) {
      this.viagensList = vl;
    },
    getDataViagem(vl) {
      this.dataViagem = vl;
    },

    getTipoViagem(vl) {
      // //console.log("tipo viagem " + vl);
      this.idaVolta = vl;
    },

    getViagemSelecionadoIda(vl) {
      this.viagemSelecionada.ida = vl;
    },
    getViagemSelecionadoVolta(vl) {
      this.viagemSelecionada.volta = vl;
    },
    getQuantidadePqssageiros(vl) {
      this.quantidadePassagens = vl;
    },
    getTaxa(vl) {
      this.taxa = vl;
    },
    onClick() {
      if (this.idaVolta === true && !this.viagemRetorno) {
        this.viagemRetorno = true;
      } else {

        let loader = this.$loading.show();

        const options = {
          method: 'POST',
          url: process.env.VUE_APP_BASE_URL + '/internet/confirmarReserva.json',
          headers: {
            'Content-Type': "application/x-www-form-urlencoded"
          },
          data: {
            "idViagem": this.viagemSelecionada.ida.idViagem,
            "idLinha": this.viagemSelecionada.ida.linhaPreco.idLinha,
            "idPercurso": this.viagemSelecionada.ida.linhaPreco.idPercurso,
            "horaSaida": this.viagemSelecionada.ida.horaSaida,
            "data": this.dataViagemIda,
            "poltronas": this.listaPoltronasIda,

            "idViagemVolta": this.viagemSelecionada.volta ? this.viagemSelecionada.volta.idViagem : null,
            "idLinhaVolta": this.viagemSelecionada.volta ? this.viagemSelecionada.volta.linhaPreco.idLinha : null,
            "idPercursoVolta": this.viagemSelecionada.volta ? this.viagemSelecionada.volta.linhaPreco.idPercurso : null,
            "horaSaidaVolta": this.viagemSelecionada.volta ? this.viagemSelecionada.volta.horaSaida : null,
            "dataVolta": this.dataViagemVolta,
            "poltronasVolta": this.listaPoltronasVolta,
            "passagensIntegral": this.quantidadePassagens.integral,
            "passagensEstudante": this.quantidadePassagens.estudante,
            "idLote": null
          }
        };

        // //console.log(options.data);
        let _this = this;
        axios.request(options).then(r => {
          // //console.log(r.data);
          // const lote = r.data.loteOcupacao;
          const tokenLote = r.data.tokenLote;
          const valorTaxa = r.data.valorTaxa;

          //console.log(valorTaxa);
          // this.viagensList = r.data.viagensProximas;
          // this.$emit('viagensList', this.viagensList);

          //TODO: Descomentar essa parte
          this.$router.push({
            path: '/dados-passageiro', query: {
              token: tokenLote,
              // marcacoes: this.marcacoes,
              // l: lote,
              t: valorTaxa,
              // vi: this.quantidadePassagens.integral,
              // ve: this.quantidadePassagens.estudante,
              // li: this.viagemSelecionada.ida.linhaPreco.idLinha
            }
          });

        }).catch(function (error) {
          if (error.response) {
            _this.$swal(
                {
                  icon: 'error',
                  title: 'Oops...',
                  text: error.response.data.mensagem,
                });
            // alert(error.response.data.mensagem);
          } else {
            _this.$swal(
                {
                  icon: 'error',
                  title: 'Oops...',
                  text: "Não foi possivel conectar ao servidor... Tente novamente.",
                });
          }
        }).finally(() => loader.hide());
      }
    }
  },
}
</script>

<style>

main .principal {
  height: calc(100vh - 7rem);
}

.scroll {
  /*background-color: #545eb0;*/
  overflow-y: auto;
  height: 100%;
}

#app {
  overflow: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*color: #7DA944;*/
  --bs-primary-rgb: 125, 169, 68;
  --bs-body-bg: #f5f5f5;
  --bs-primary: #7DA944;
}

@media screen and (min-width: 900px) {
  main .principal {
    height: calc(100vh - 13.3rem);
  }
}
</style>
