<template>
  <div class="dropdown">
    <input type="text"
           class="form-control"
           :placeholder="placeholder"
           @input="onInput($event.target.value)"
           @keydown="onKeydown"
           @blur="onBlur"
           ref="input"
           :value="keyword">

    <div v-show="carregando" class="dropdown-menu show">
      <li>
        <div class="d-flex justify-content-center">
          <div class="spinner-border spinner-border-sm text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </li>
    </div>

    <div v-show="mutableOptions.length === 0 && keyword.length > 3 && !itemSelecionado" class="dropdown-menu show">
      <li class="dropdown-item-text">
        <span  class="text-muted fs-6 fst-italic">Nenhuma cidade encontrada</span>
      </li>
    </div>

    <div v-show="mutableOptions.length" class="dropdown-menu show">
      <li v-for="(opt, index) in mutableOptions"
          :key="opt[valueKey]"
          :ref="`option_${index}`"
          class="autocomplete-item"
          :class="{'list-group-item-success': arrowContuer === index}"
          @click="onSelect()"
          tabindex="0"
          @mouseover="setArrowCounter(index)"
      >
        <a class="autocomplete-item dropdown-item" href="#" @click="onSelect()">
          <span
              class="fw-normal"
              v-html="opt[`${labelKey}_highlight`] || opt[labelKey]"
          />
        </a>
      </li>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AutoComplete',
  props: {
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => [],
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    placeholder:{
      type: String,
      default: ''
    },
    searchMinLength:{
      type: Number,
      default: 3
    },
    showSpinner: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue) {
      this.keyword = newValue;
    },
    options(){
      this.cloneOptions();
    },
    showSpinner(newValue) {
      this.carregando = newValue;
    }
  },
  data() {
    return {
      keyword: '',
      arrowContuer: 0,
      originalOptions: [],
      mutableOptions: [],
      carregando: false,
      itemSelecionado: false
    }
  },
  created() {
    this.keyword = this.value;
    if (this.options.length){
      this.cloneOptions();
    }
  },
  methods: {
    onInput(vl) {
      this.itemSelecionado = false;
      this.keyword = vl;
      this.emitInput();
      if (vl.length >= this.searchMinLength){
        if (!this.originalOptions.length){
          this.$emit('shouldSearch', vl);
        }else{
          this.procurarIternamente();
        }
      } else {
        this.resetOptions();
      }
    },

    procurarIternamente(){
      const busca = this.keyword;
      this.mutableOptions  = this.originalOptions.filter((obj) => {
        return obj[this.labelKey].toLowerCase().search(busca.toLowerCase()) >= 0;
      });
      this.highlightOptions();
    },

    highlightOptions(){
      const busca = this.keyword;
      const query = new RegExp(busca, 'i');

      this.mutableOptions.forEach((obj) => {
        obj[`${this.labelKey}_highlight`] = obj[this.labelKey].replace(query, '<span class="fw-bold">$&</span>');
      });
    },

    onKeydown(env){
      if (!this.mutableOptions.length){ return; }
      switch (env.code){
        case 'ArrowDown':
          env.preventDefault();
          this.onArrowDown();
          break;
        case 'ArrowUp':
          env.preventDefault();
          this.onArrowUp();
          break;
        case 'Enter':
          this.onSelect();
          break;
        case 'Escape':
          this.onEsc();
          break;
      }
    },

    onEsc() {
      this.$refs.input.blur();
      this.resetArrowCounter();
      this.resetOptions();
    },

    onBlur(env){
      const tgt = env.relatedTarget;
      if (tgt && tgt.classList.contains('autocomplete-item')) {
        return;
      }

      this.resetOptions();
      this.resetArrowCounter();
    },

    onArrowDown(){
      if (this.arrowContuer < this.mutableOptions.length - 1) {
        this.arrowContuer += 1;
      }
      this.fixScrolling();
    },

    onArrowUp(){
      if (this.arrowContuer > 0) {
        this.arrowContuer -= 1;
      }
      this.fixScrolling();
    },
    onSelect(){
      const opt = this.mutableOptions[this.arrowContuer];
      const selectedOption = this.options.find(o => o[this.valueKey] === opt[this.valueKey]);

      if (selectedOption) {
        this.$emit('select', opt.id);
        this.keyword = opt[this.labelKey];
        this.itemSelecionado = true;
        this.emitInput();
        this.resetOptions();
        this.resetArrowCounter();
      }
    },

    fixScrolling(){
      this.$refs[`option_${this.arrowContuer}`][0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    },

    setArrowCounter(number){
      this.arrowContuer = number;
    },

    resetArrowCounter(){
      this.arrowContuer = 0;
    },

    emitInput(){
      this.$emit('update:value', this.keyword);
    },

    cloneOptions(){
      this.originalOptions = JSON.parse(JSON.stringify(this.options));
      this.mutableOptions = JSON.parse(JSON.stringify(this.options));
      this.procurarIternamente();
    },

    resetOptions() {
      this.originalOptions = [];
      this.mutableOptions = [];
    }
  },
}
</script>

<style scoped>

.dropdown-menu {
  min-width: 100%;
  /*width: 100%;*/
  max-height: 200px;
  overflow-y: scroll;
}

.dropdown-item {
  white-space: normal;
}

</style>