<template>
<footer class="bg-secondary bg-opacity-10 text-muted text-center py-3 shadow">
  <div class="container">
    <p class="mb-1">
      <img src="../../assets/imgs/logo.png" width="70" class="me-4">
      Telefone para informações: (84) 9 9427-4318 <font-awesome-icon icon="fa-brands fa-whatsapp" />
    </p>
  </div>
</footer>
</template>

<script>
export default {
  name: "FooterComponente",
}
</script>

<style scoped>

</style>