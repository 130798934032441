import "bootstrap/dist/css/bootstrap.css"
import "@/css/global.css"
import "@/scss/main.scss"
import Vue from 'vue'
import App from './App.vue'
import VCalendar from 'v-calendar';
import VueRouter from "vue-router";
import ListarViagens from "@/components/listarViagens";
import PagamentoSucesso from "@/components/comuns/PagamentoSucesso";
import InformacoesPassageiro from "@/components/passageiro/InformacoesPassageiro";
import VueMask from 'v-mask';
import VueFeather from 'vue-feather';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'

import Popover from 'vue-js-popover'

/* add icons to the library */
library.add(faUserSecret)
library.add(faWhatsapp)
library.add(faCircleQuestion)
library.add(faCircle)
// library.add(faUserbrands)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

const routes = [
    {
        path: '/',
        name: 'home',
        component: ListarViagens
    },
    {
        path: '/success',
        component: PagamentoSucesso,
        props: (route) => ({
            p: route.query.preference_id
        })
    },
    {
        path: '/retornoPendente',
        component: PagamentoPendente,
        props: (route) => ({
            p: route.query.preference_id
        })
    },
    {
        path: '/dados-passageiro',
        name: 'dadosPassageiros',
        component: InformacoesPassageiro,
        props: (route) => ({
            // marcacoes: route.query.marcacoes,
            // l: route.query.l,
            // t: route.query.t,
            token: route.query.token,
            // linhaId: route.query.li,
            // quantidadePassagens: {
            //     integral: route.query.vi,
            //     estudante: route.query.ve
            // }
        })
    }
]

const router = new VueRouter({
    routes
})

Vue.use(VCalendar, {});
Vue.use(VueRouter);
Vue.use(VueFeather);
Vue.use(VueMask);
Vue.use(Popover);
Vue.use(Loading, {
    color: "#7DA944",
    loader: "dots"
});
Vue.use(VueSweetalert2, {
    confirmButtonColor: '#7DA944',
});

new Vue({
    router: router,
    render: h => h(App),
}).$mount('#app')

import "bootstrap/dist/js/bootstrap"
import PagamentoPendente from "@/components/comuns/PagamentoPendente.vue";

// import informacoesPassageiro from "@/InformacoesPassageiro";
