<template>
  <div v-if="widowWidth > 900" style="height: 100%">
    <div v-if="marcacoesList.viagemIda == null"
         class="detalhes-compra ps-4 bg-white d-flex flex-column justify-content-between">
      <p class="mt-5 fs-6 fst-italic text-primary">Nenhuma poltrona <b>selecionada</b></p>
    </div>
    <div v-else class="detalhes-compra ps-4 bg-white d-flex flex-column justify-content-between">
      <detalhes-poltronas-marcadas :marcacoes-list="listaPoltronas" :etapa-pagamento="etapaPagamento"/>

      <div class="container pb-3 pt-1">
        <hr>
        <div class="d-grid gap-2">
          <!--        <router-link to="/dados-passageiro" class="btn btn-danger btn-lg text-white fw-semibold" :class="listaPoltronas.length === 0 ? 'disabled' : ''">CONTINUAR</router-link>-->
          <button v-show="verificarBotaoContinuar" @click="onClickContinuar"
                  class="btn btn-danger btn-lg text-white fw-semibold"
                  :class="desativarBotaoContinuar"
                  type="button">{{ labelBtn }}
          </button>
          <div v-show="etapaPagamento" class="row">
            <div class="col-6 d-flex flex-column">
              <span class="text-primary fs-5">Preço final</span>
              <span class="text-black-50 fs-6 fw-lighter">{{formatNumber(this.valorSemTaxa)}} + {{formatNumber(this.valorTaxa)}}</span>
              <a v-popover:foo.top><span class="text-black-50 fs-6 fw-lighter">(Passagens + taxas) <font-awesome-icon
                  icon="fa-regular fa-circle-question"/></span></a>
              <popover name="foo" event="hover">
              <span class="text-black-50 fs-6 fw-lighter">
                Através da cobrança desta taxa podemos oferecer um serviço seguro,
                confortável e transparente para você adquirir sua passagem com antecedência.
              </span>
              </popover>
            </div>
            <div class="col-6 text-end">
              <span class="fw-bold">{{ formatNumber(valorTotal) }}</span>
            </div>
          </div>
        </div>
        <!--      <div class="btn btn-danger btn-lg">CONTINUAR</div>-->
      </div>
    </div>
  </div>

  <div v-else-if="widowWidth <= 900 && verificarBotaoContinuar">
    <div class="container pb-3 pt-1 botao-mobile bg-white">
      <hr>
      <div class="d-grid gap-2">
        <!--        <router-link to="/dados-passageiro" class="btn btn-danger btn-lg text-white fw-semibold" :class="listaPoltronas.length === 0 ? 'disabled' : ''">CONTINUAR</router-link>-->
        <button v-show="verificarBotaoContinuar" @click="onClickContinuar"
                class="btn btn-danger btn-lg text-white fw-semibold"
                :class="desativarBotaoContinuar"
                type="button">{{ labelBtn }}
        </button>
        <div v-show="etapaPagamento" class="row">
          <div class="col-6 d-flex flex-column">
            <span class="text-primary fs-5">Preço final</span>
            <span class="text-black-50 fs-6 fw-lighter">{{formatNumber(this.valorSemTaxa)}} + {{formatNumber(this.valorTaxa)}}</span>
            <a v-popover:foo.top><span class="text-black-50 fs-6 fw-lighter">(Passagens + taxas) <font-awesome-icon
                icon="fa-regular fa-circle-question"/></span></a>
            <popover name="foo" event="hover">
              <span class="text-black-50 fs-6 fw-lighter">
                Através da cobrança desta taxa podemos oferecer um serviço seguro,
                confortável e transparente para você adquirir sua passagem com antecedência.
              </span>
            </popover>

          </div>
          <div class="col-6 text-end">
            <span class="fw-bold">{{ formatNumber(valorTotal) }}</span>
          </div>
        </div>
      </div>
      <!--      <div class="btn btn-danger btn-lg">CONTINUAR</div>-->
    </div>
  </div>
</template>

<script>

import DetalhesPoltronasMarcadas from "@/components/comuns/detalhesPoltronasMarcadas.vue";

export default {
  name: "DetalhesCompra",
  props: {
    valorTotal: {
      type:Number,
      default: 0.0,
    },
    valorSemTaxa: {
      type:Number,
      default: 0.0,
    },
    taxa: {
      type: Number,
      default: 0.0
    },
    quantidadePassageiros: {
      type: Object,
      default: () => ({
        integral: 0,
        estudante: 0
      })
    },
    etapaPagamento: {
      type: Boolean,
      default: false
    },
    viagemRetorno: {
      type: Boolean,
      default: false
    },
    marcacoesList: {
      type: Object,
      default: () => ({
        viagemIda: [],
        viagemVolta: []
      })
    },
    labelBtn: {
      type: String,
      default: 'CONTINUAR'
    }
  },
  components: {
    DetalhesPoltronasMarcadas,
  },
  watch: {
    marcacoesList(newValue) {
      this.listaPoltronas = newValue;
      // this.somarPassagens();
    }
  },
  computed: {
    quantidadeTotalPassageiros() {
      return parseInt(this.quantidadePassageiros.estudante) + parseInt(this.quantidadePassageiros.integral);
    },
    desativarBotaoContinuar() {
      let quantidadeViagensIda = this.listaPoltronas.viagemIda.length;
      let quantidadeViagensVolta = this.listaPoltronas.viagemVolta.length

      if (this.quantidadeTotalPassageiros !== quantidadeViagensIda) {
        return 'disabled';
      } else if (quantidadeViagensIda === 0) {
        return 'disabled';
      } else if ((quantidadeViagensVolta === 0 || quantidadeViagensVolta < quantidadeViagensIda) && this.viagemRetorno) {
        return 'disabled';
      } else {
        return '';
      }
    },
    verificarBotaoContinuar() {
      let quantidadeViagensIda = this.listaPoltronas.viagemIda.length;
      let quantidadeViagensVolta = this.listaPoltronas.viagemVolta.length

      if (this.quantidadeTotalPassageiros !== quantidadeViagensIda) {
        return false;
      } else if (quantidadeViagensIda === 0) {
        return false;
      } else return !((quantidadeViagensVolta === 0 || quantidadeViagensVolta < quantidadeViagensIda) && this.viagemRetorno);
    },
    valorTaxa() {
      // let total = this.totalSemTaxa;
      return parseFloat(this.taxa);
    },
    // totalSemTaxa() {
    //   let total = 0;
    //   const valorViagemIda = this.listaPoltronas.viagemIda[0] ? this.listaPoltronas.viagemIda[0].preco : 0;
    //   const valorViagemVolta = this.listaPoltronas.viagemVolta[0] ? this.listaPoltronas.viagemVolta[0].preco : 0;
    //
    //   let totalViagemIda = (this.quantidadePassageiros.integral * valorViagemIda) + (this.quantidadePassageiros.estudante * (valorViagemIda / 2));
    //   let totalViagemVolta = (this.quantidadePassageiros.integral * valorViagemVolta) + (this.quantidadePassageiros.estudante * (valorViagemVolta / 2));
    //   total = totalViagemIda + totalViagemVolta;
    //
    //   return total;
    // },
    // totalPassagens() {
    //   let total = this.totalSemTaxa;
    //
    //   if (total > 0) {
    //     total = total + (this.valorTaxa);
    //   }
    //
    //   return total;
    // }
  },
  methods: {
    formatNumber(numero) {
      let real = Intl.NumberFormat('pt-BR', {
        style: "currency",
        currency: "BRL",
      });
      return real.format(numero);
    },

    onClickContinuar() {
      this.$emit('onClick');
    },
    removePoltronaIda(poltrona) {

      this.listaPoltronas.viagemIda.forEach((obj) => {
        if (obj.label === poltrona) {
          obj.statusPoltrona = 'L'
        }
      });
      this.listaPoltronas.viagemIda = this.listaPoltronas.viagemIda.filter((obj) => obj.label != poltrona);
    },
    removePoltronaVolta(poltrona) {
      this.listaPoltronas.viagemVolta.forEach((obj) => {
        if (obj.label === poltrona) {
          obj.statusPoltrona = 'L'
        }
      });
      this.listaPoltronas.viagemVolta = this.listaPoltronas.viagemVolta.filter((obj) => obj.label != poltrona);
    }
  },
  created() {
    this.listaPoltronas = this.marcacoesList;
  },
  data() {
    return {
      listaPoltronas: {},
      widowWidth: window.innerWidth,
    }
  },
  mounted() {
    window.onresize = () => {
      this.widowWidth = window.innerWidth;
    }
  },
}
</script>

<style scoped>
.detalhes-compra {
  height: 100%;
  box-sizing: border-box;
}


.detalhes-compra .passagens {
  overflow: auto;
}

.detalhes-compra .confirm {
  background-color: red;
  height: 100px;
  position: relative;
  bottom: 0;
  left: 0;
}

.botao-mobile {
  min-width: 100%;
  position: absolute;
  justify-content: center;
}
</style>